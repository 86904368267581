import React from 'react';
import styles from './about.module.scss'

function About() {

  return (
    <header id="hello" className={styles.about}>
      <div className="wrapper">
          <div className={styles.aboutText}>
            <h1 className={styles.name}> Nick Gourlay</h1>
            <h2 className={styles.oneLiner}>front-end web developer</h2>
            <p className={styles.introDetails}>
              If you'd like to work together please get 
            <a href={"#contact"} className={styles.link}> in touch</a>
            </p>
            <div className={styles.logos}>
              <a href={'https://github.com/NicholG90'} target="_blank" rel="noreferrer" className={styles.logoLink}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" className={styles.logoimage}>    
                <path d="M15,3C8.373,3,3,8.373,3,15c0,5.623,3.872,10.328,9.092,11.63C12.036,26.468,12,26.28,12,26.047v-2.051 c-0.487,0-1.303,0-1.508,0c-0.821,0-1.551-0.353-1.905-1.009c-0.393-0.729-0.461-1.844-1.435-2.526 c-0.289-0.227-0.069-0.486,0.264-0.451c0.615,0.174,1.125,0.596,1.605,1.222c0.478,0.627,0.703,0.769,1.596,0.769 c0.433,0,1.081-0.025,1.691-0.121c0.328-0.833,0.895-1.6,1.588-1.962c-3.996-0.411-5.903-2.399-5.903-5.098 c0-1.162,0.495-2.286,1.336-3.233C9.053,10.647,8.706,8.73,9.435,8c1.798,0,2.885,1.166,3.146,1.481C13.477,9.174,14.461,9,15.495,9 c1.036,0,2.024,0.174,2.922,0.483C18.675,9.17,19.763,8,21.565,8c0.732,0.731,0.381,2.656,0.102,3.594 c0.836,0.945,1.328,2.066,1.328,3.226c0,2.697-1.904,4.684-5.894,5.097C18.199,20.49,19,22.1,19,23.313v2.734 c0,0.104-0.023,0.179-0.035,0.268C23.641,24.676,27,20.236,27,15C27,8.373,21.627,3,15,3z" />
              </svg>
                <span className='sr-only'>Github Link</span>
              </a>
            <a href={'https://www.linkedin.com/in/nickgourlay/'} target="_blank" rel="noreferrer" className={styles.logoLink} >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" className={styles.logoimage}>    
                <path d="M25,2C12.318,2,2,12.317,2,25s10.318,23,23,23s23-10.317,23-23S37.682,2,25,2z M18,35h-4V20h4V35z M16,17 c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2s2,0.895,2,2C18,16.105,17.105,17,16,17z M37,35h-4v-5v-2.5c0-1.925-1.575-3.5-3.5-3.5 S26,25.575,26,27.5V35h-4V20h4v1.816C27.168,20.694,28.752,20,30.5,20c3.59,0,6.5,2.91,6.5,6.5V35z" />
              </svg>
              </a>
            </div>
          </div>
        <a href={"#aboutme"} className={styles.arrowLink}><div className={styles.imgContainer}><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className={styles.arrow}><path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path></svg></div></a>
      </div>
    </header>
  );
}

export default About;
